import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
import $ from "jquery";

const URL_API = process.env.VUE_APP_URL_API;
// const URL_API = "http://localhost:8000/api/" ;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getUser() {
      try {
        const resp = await axios.get(URL_API + "user", { headers: authHeader });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async sentEmailToProductOwner(data) {
      try {
        const resp = await axios.post(URL_API + "booking", data, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async payBooking(data) {
      try {
        const resp = await axios.post(URL_API + "pay", data, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async contactAgent(data) {
      try {
        const resp = await axios.post(URL_API + "contactAgent", { data });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getProducts(page) {
      try {
        const resp = await axios.get(URL_API + `Client?page=${page}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async loadMorePorduct(page) {
      try {
        const response = await axios.get(URL_API + `Client?page=${page}`, {
          headers: authHeader,
        });

        return response;
      } catch (error) {
        return error;
      }
    },

    async getProductById(id) {
      try {
        const resp = await axios.get(URL_API + `Client/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },
    async searchChildHood(value) {
      try {
        const resp = await axios.get(
          URL_API + `ClientSearchChildHood?query=${value}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },
    async searchProduct(value) {
      try {
        const resp = await axios.get(
          URL_API + `ClientSearchProduct?query=${value}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getProductByDistrict(value, optionTabSelectSearch) {
      try {
        const resp = await axios.get(
          URL_API +
            `getProductByDistrict?query=${value}&optionSelected=${optionTabSelectSearch}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },
    async getAgentsProducts(agent_id) {
      try {
        const resp = await axios.get(
          URL_API + `getAgentsProducts?agent_id=${agent_id}`
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async addReview(data) {
      try {
        const resp = await axios.post(
          URL_API + `Review`,
          { data },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getReviews(product_id) {
      try {
        const resp = await axios.get(
          URL_API + `getReviews?query=${product_id}`
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async add_statistic_product(data) {
      try {
        const resp = await axios.post(
          URL_API + `statistic_product`,
          { data },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async queryDataFilter(buyOrRent = null, location = null, price) {
      try {
        const resp = await axios.get(
          URL_API +
            `get_product_By_filter?location=${location}&buyOrRent=${buyOrRent}&minPrice=${price.minPrice}&maxPrice=${price.maxPrice}`
        );
        return resp;
      } catch (error) {
        return error;
      }
    },
    async getProductByFilter(location, buyOrRent, price) {
      try {
        const resp = await axios.get(
          URL_API +
            `get_product_By_filter?location=${location}&buyOrRent=${buyOrRent}&minPrice=${price.minPrice}&maxPrice=${price.maxPrice}`
        );
        return resp;
      } catch (error) {}
    },

    async getDistricts() {
      try {
        const resp = await axios.get(URL_API + `getDistricts`);
        return resp;
      } catch (error) {}
    },

    async getAgentByDistrict(AgentName, district_id) {
      try {
        const resp = await axios.get(
          URL_API +
            `getAgentByDistrict?AgentName=${AgentName}&query=${district_id}`
        );
        return resp;
      } catch (error) {}
    },

    async getAllAgent() {
      try {
        const resp = await axios.get(URL_API + `getAllAgent`);
        return resp;
      } catch (error) {}
    },

    async ServicePersonalized(data) {
      try {
        const resp = await axios.post(URL_API + `ServicePersonalized`, {
          data,
        });
        return resp;
      } catch (error) {}
    },

    async PeopleFeedBack(data) {
      try {
        const resp = await axios.post(URL_API + `PeopleFeedBack`, { data });
        return resp;
      } catch (error) {}
    },

    async moreFilters(
      minArea,
      maxArea,
      numberOfWc,
      lift,
      marketState,
      real_state_category,
      published_at
    ) {
      try {
        const resp = await axios.get(
          URL_API +
            `get_moreFilters?minArea=${minArea}&maxArea=${maxArea}&numberOfWc=${numberOfWc}&lift=${lift}
            &marketState=${marketState}&real_state_category=${real_state_category}&published_at=${published_at}`
        );

        return resp;
      } catch (error) {}
    },
    async visit() {
      return axios.get(URL_API + "visits");
    },
    async getTotvisits() {
      return axios.get(URL_API + "getTotvisits", { headers: authHeader() });
    },
  },
};

export const pt_pt = {
  BarMenu: {
    Home: "Inicio",
    Sell: "Vender",
    Buy: "Comprar",
    Account: "Minha Conta",
    Languages: "Linguas",
    Login: "Entrar",
    Logout: "Sair",
    Credentials: "Credencias",
    MyProduct: "Meus Produtos",
    Product: "Produtos",
    Add: "Adicionar",
    List: "Listar",
    Categories: "Categorias",
    Categorie: "Categoria",
    Agents: "Agentes",
    Profile: "Perfil",
    Agent: "Agente",
    ProporsalClients: "Propostas",
    agence: "Agencia",
    franchise: "Franquia",
    BecomeAgent: "Seja um agente",
    ListProductAvailabe: "Produtos Disponiveis",
    Candidate: "Candidaturas",
    beAgent: "Quero ser agente",
    contact: "Contactos",
    service: "Serviços",
    bookings: "Reservas",
  },

  FormLogin: {
    ForgotPassword: "Esqueceu a senha",
    RemberMe: "Lembrar-me",
    Password:
      "Senha de pelo menos 8 dígitos, contendo maiúsculas, minúsculas e dígitos  ",
    AccessAccount: "Acesse a sua conta",
    SignUp: "Ainda não tem uma conta",
    clickTitle: "Clique Aqui",
    Access: "Entrar",
    Accessing: "Entrando...",
    recoverEmail: "Recuperar sua senha",
    update: "Actualizar",
    updating: "Actualizando...",
    Google: "Entrar com conta google",
  },

  HomePage: {
    FindYourProduct: "Encontre o seu produto",
    RealState: "Comprar",
    Land: "Arrendar",
    News: "Quartos",
    SearchProductInput: "Onde?Bairro ou cidade ou provincia",
  },

  Footer: {
    aboutSite:
      "Você procura por um espaço ou quer comprar um trespasse, nós somos a solução ideal.",
    Links: {
      Home: "Inicio",
      ProductDateils: "Detalhes do Produto",
      Agents: "Agentes",
      ContactUs: "Contacte-nos",
      HelpUs: "Ajude-nos a melhorar a plateforma",
    },
    MyAccount: {
      WhyTransapss: "Porque - Terrenos Transpasse",
      Contacts: "Contactos",
      PolicePrivicy: "Politica Privacidade",
      AcessYouData: "Acesso ao seus dados pessoas",
      PrivacyState: "Declaracao de Privacidade",
      CondOfUse: "Condicoes de uso",
      SafetyTips: "Dicas de Seguranca",
      Newsletter: "Boletim Informativo",
      KeepInformed:
        "Queremos te mater informado das nossas novidades inscreva-te",
      YourEmail: "Seu Email",
      SignUp: "Inscreva-te",
      FallowUs: "Siga-nos",
      MyAccount: "Minha Conta",
    },
  },

  PageDateils: {
    Description: "Descrições",
    Dateils: "Detalhes",
    Divisions: "Divisões",
    Maps: "Mapa",
    ContactAgent: "Contactar Agente",
    Comment: "Proposta",
    Bedroom: "Quartos",
    Lift: "Elevador",
    Parking: "Parque",
    ProductAvailableRent: "Aluguer",
    ProductAvailableSell: "Venda",
  },

  PageFilter: {
    InfoStatusMessage:
      "Não há imóveis que obedeçam ao seu critério de procura!",
    InfoMessageSmall: "Por favor tente uma nova pesquisa.",
    FilterBtnText: "Filtros",
    ServiceBtnText: "Serviço P",
    Price: "Preço",
    Location: "Localização",
    DefineIntervalPrice: "Defina o seu intervalo de preços",
    BtnAply: "Aplicar",
    BtnRent: "Aluguer",
    MoreFilterTitle: "Mais Filtros",
    WcLabel: "Casas de Banho",
    AreaLabel: "Área m2",
    Lift: "Elevador",
    MarketState: "Estado do Mercado",
    CategorieRealEstate: "Categoria do Imóvel",
    Published: "Publicado",
    ServicePersonalized: "Serviços Personalizado",
    ProductCategorie: "Categoria do Produto",
    RentOrBuy: "Aluguer/Comprar",
    Title: "Título",
    district: "Selecione Distrito",
    ProvinceLabel: "Selecione Provincia",
    MarketStateLabel: "Selecione Estado  do Mercado",
    PlaceHolderCategorie: "Por Favor Selecione a Categoria",
    PlaceHolderCategorieRentOrBuy:
      "Por Favor Selecione se Deseja Alugar/Comprar",
    PlaceHolderContact: "Informe Seu Contacto",
    PlaceHolderEmail: "Informe Seu Email",
    Loading: "Carregando mais Produtos",
    Any: "Qualquer",
    DescriptionP:
      "Por Favor Deixe sua Descrição aqui, menciona tambem as infra-estruturas mais próximas que deseja para facilitar os nossos agentes...",
    Send: "Enviar",
    Cancel: "Cancelar",
    CleanTitle: "Limpar",
    Description: "Descrição",
    SelectTime: "Por Favor Selecione o Tempo",
  },

  AdminDashBoard: {
    TotalProduct: "Total Produtos",
    TotalAgents: "Total Agentes",
    ProductSell: "Produtos Vendidos",
    ProductRent: "Produtos Reservados",
    Sell: "Vendas",
    Rent: "Aluguer",
    LastFiveProducts: "Últimos 5 Produtos",
    Type: "Tipo",
    Categorie: "Categoria",
    Available: "Disponivel",
    Date: "Data",
    Province: "Provincia",
    District: "Distrito",
    Neighborhood: "Bairro",
    DragAndDrop: "Arrastar e soltar imagem",
    DragAndDropAgent: "Arrastar e soltar imagem de perfil",
    DragAndDropPlaceholder: "Arrastar e Soltar a imagem aqui ou",
    DragAndDropPlaceholderAgent: "Arrastar e Soltar a imagem de perfil aqui ou",
    DragAndDropPlaceholderV: "Arrastar e soltar o video",
    DragAndDropPlaceholderVideo: "Arrastar e soltar o video aqui ou",
    Choose: "Escolha",
    Floor: "Piso",
    BuildYear: "Ano de construção",
    BruteArea: "Área Bruta*",
    Parking: "Estacionamento",
    Back: "Back",
    AreaLoteTotal: "Área Total do Lote",
    AreaUtil: "Área Útil",
    Submit: "Submeter",
    BruteAreaPrimitive: "Área Bruta Privativa",
    Next: "Próximo",
    Visits: "Visitas",
  },

  FormAgents: {
    Title: "Cadastrar Novo Agente",
    CompleteName: "Nome Completo",
    BirthDate: "Data de Nascimento",
    Gender: "Gênero",
    MaritalStatus: "Estado Civil",
    BI: "BI",
    AlternativeContact: "Contacto Alternativo*",
  },
};

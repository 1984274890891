<template>
  <div class="container text-center">
    <h1>Transação Cancelada</h1>
    <p>Token: {{ token }}</p>

    <Button type="info" class="mt-3" @click="backhistory">Voltar</Button>
  </div>
</template>
  
  <script setup>
import { Button } from "view-ui-plus";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const token = ref(route.query.token);

function backhistory() {
  let det = localStorage.getItem("url");
  window.location.href = `${det}`;
}

onMounted(() => {
  console.log(route.query.token);
});
</script>
  
import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "http://localhost:8000/api/";
// const URL_API = "https://terrenos.co.mz/Api/api/" ;
const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async addProduct(data, CoverImage, licence, files, video) {
      try {
        let data1 = new FormData();

        data1.append("productCategory", data.productCategory);
        data1.append("title", data.title);
        data1.append("price", data.price);
        data1.append("category", data.category);
        data1.append("private_gross", data.private_gross);
        data1.append("total_lot_area", data.total_lot_area);
        data1.append("qtybadRoom", data.qtybadRoom);
        data1.append("net_area", data.net_area);
        data1.append("floor", data.floor);
        data1.append("lift", data.lift);
        data1.append("gross_area", data.gross_area);
        data1.append("build_year", data.build_year);
        data1.append("wc", data.wc);
        data1.append("parking", data.parking);
        data1.append("divisions", data.divisions);
        data1.append("agent_id", data.agent_id);
        data1.append("name", data.name);
        data1.append("contact", data.contact);
        data1.append("contact_alternative", data.contact_alternative);
        data1.append("email", data.email);
        data1.append("whatsapp", data.whatsapp);
        data1.append("district_id", data.district_id);
        data1.append("description", data.description);
        data1.append("childhood", data.childhood);
        data1.append("role", data.role);
        data1.append("video", video);
        data1.append("coverImage", CoverImage);
        data1.append("licence", licence);
        data1.append("lat", data.lat);
        data1.append("lng", data.lng);

        for (let index = 0; index < files.length; index++) {
          data1.append("filesImg[]", files[index]);
        }

        const resp = await axios.post(URL_API + "Product", data1, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async removeImg(imageName) {
      try {
        const resp = await axios.get(
          URL_API + `removeImage?query=${imageName}`,
          {
            headers: authHeader(),
          }
        );

        return resp;
      } catch (error) {}
    },

    async provinces() {
      try {
        const resp = await axios.get(URL_API + "provinces", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async districtsByProvinceId(id) {
      try {
        const resp = await axios.get(URL_API + `district/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async districtsByProvinceIdClient(id) {
      try {
        const resp = await axios.get(URL_API + `district/${id}`);

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getProduct(queryParams) {
      try {
        const resp = await axios.get(
          URL_API +
            `Product?page=${queryParams.page}&search=${queryParams.search}`,
          {
            headers: authHeader(),
          }
        );

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAllProducts() {
      try {
        const resp = await axios.get(URL_API + `getAllProducts`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },
    async getPriceHistory(pid) {
      return await axios.get(URL_API + `getPriceHistory/${pid}`, {
        headers: authHeader(),
      });
    },
    async productAdmin() {
      try {
        const resp = await axios.get(URL_API + `productAdmin`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getProductById(id) {
      try {
        const resp = await axios.get(URL_API + `Product/` + id, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async updateProduct(data, coverImage, files, id) {
      try {
        let dat = new FormData();

        dat.append("title", data.title);
        dat.append("price", data.price);
        dat.append("private_gross", data.private_gross);
        dat.append("total_lot_area", data.total_lot_area);
        dat.append("qtybadRoom", data.qtybadRoom);
        dat.append("net_area", data.net_area);
        dat.append("floor", data.floor);
        dat.append("lift", data.lift);
        dat.append("gross_area", data.gross_area);
        dat.append("build_year", data.build_year);
        dat.append("wc", data.wc);
        dat.append("parking", data.parking);
        dat.append("divisions", data.divisions);
        dat.append("name", data.name);
        dat.append("contact", data.contact);
        dat.append("contact_alternative", data.contact_alternative);
        dat.append("email", data.email);
        dat.append("description", data.description);
        dat.append("childhood", data.childhood);
        dat.append("product_id", id);
        dat.append("coverImage", coverImage);

        for (let index = 0; index < files.length; index++) {
          dat.append("filesImg[]", files[index]);
        }

        const resp = await axios.post(URL_API + `updateProduct`, dat, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getMyProduct(queryParams) {
      try {
        const resp = await axios.get(
          URL_API +
            `getMyProduct?page=${queryParams.page}&search=${queryParams.search}&user_id=${queryParams.user_id}`,
          {
            headers: authHeader(),
          }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async approveProduct(id) {
      try {
        const resp = await axios.get(
          URL_API + `approveProduct?product_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async UnapproveProduct(id) {
      try {
        const resp = await axios.get(
          URL_API + `UnapproveProduct?product_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async markProductUnvailable(id) {
      try {
        const resp = await axios.get(
          URL_API + `markProductUnvailable?product_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async markProductAvailable(id) {
      try {
        const resp = await axios.get(
          URL_API + `markProductAvailable?product_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async removeProduct(id) {
      try {
        const resp = await axios.get(
          URL_API + `removeProduct?product_id=${id}`,
          {
            headers: authHeader(),
          }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getMyTotalProduct(id) {
      try {
        const resp = await axios.get(
          URL_API + `getMyTotalProduct?person_id=${id}`,
          {
            headers: authHeader(),
          }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getTotalProductAvailable(id) {
      try {
        const resp = await axios.get(
          URL_API + `getTotalProductAvailable?person_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getTotalProductUnvailable(id) {
      try {
        const resp = await axios.get(
          URL_API + `getTotalProductUnvailable?person_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getTotalProductUnvailableRent(id) {
      try {
        const resp = await axios.get(
          URL_API + `getTotalProductUnvailableRent?person_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getLastFiveProducts() {
      try {
        const resp = await axios.get(URL_API + `getLastFiveProducts`, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },
    async getTotSalesOfEachMonth() {
      try {
        const resp = await axios.get(URL_API + `getTotSalesbOfEachMonth`, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },
    async getTotProdAvailableOfEachMonth() {
      try {
        const resp = await axios.get(
          URL_API + `getTotProdAvailableOfEachMonth`,
          {
            headers: authHeader(),
          }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },
    async getTotalLand() {
      try {
        const resp = await axios.get(URL_API + `getTotalLand`, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getTotalRealestate() {
      try {
        const resp = await axios.get(URL_API + `getTotalRealestate`, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },
    async payment(token) {
      try {
        const resp = await axios.get(
          URL_API + `confirmPayment?payment_id=${token}`,
          {
            headers: authHeader(),
          }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },
  },
};

<template>
  <BarMenu :fullName="fullName" :lang="lang" :role="role" />
  <!-- Carousel Start -->
  <div class="container mb-3 d-none d-sm-none d-md-none d-lg-block">
    <div class="row">
      <div class="col-lg-8">
        <div
          id="header-carousel"
          class="carousel slide carousel-fade mb-30 mb-lg-0"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <!-- <div
              class="carousel-item position-relative active"
              style="height: 430px"
            >
              <img
                class="position-absolute w-100 h-100"
                :src="post_0"
                style="object-fit: cover"
                alt="empty"
              />
            </div> -->
            <!-- <div
              class="carousel-caption d-flex flex-column align-items-center justify-content-center"
            ></div> -->
            <div
              class="carousel-item position-relative active"
              style="height: 430px"
            >
              <img
                alt="empty"
                class="position-absolute w-100 h-100"
                src="/assets/img/slide.webp"
                style="object-fit: cover"
              />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              ></div>
            </div>
            <!-- <div class="carousel-item position-relative" style="height: 430px">
              <img
                alt="empty"
                class="position-absolute w-100 h-100"
                src="assets/img/Terrenos - Post 3.webp"
                style="object-fit: cover"
              />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              ></div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="product-offer mb-30" style="height: 200px">
          <img alt="empty" class="img-fluid" :src="post_1" />
          <div class="offer-text"></div>
        </div>
        <div class="product-offer mb-30" style="height: 200px">
          <img class="img-fluid" :src="post_2" alt="empty" />
          <div class="offer-text"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Carousel End -->

  <!--  Start search-->
  <!-- <AutoComplete
    :items="items"
    label-key="label"
    value-key="id"
    @shouldSearch="searchChildhoods"
    @itemSearchProducts="findProducts"
    v-model="childhood"
  /> -->
  <!--End Search  -->
  <!-- Products Start -->
  <div class="container pt-5 pb-3">
    <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4">
      <span class="bg-secondary pr-3">{{ t("HomePage.News") }}</span>
    </h2>

    <div class="row" style="min-height: 600px">
      <div
        style="border-top-left-radius: 6px; border-top-right-radius: 6px"
        class="col-6 col-lg-3 col-md-4 col-sm-6 col-xs-6 pb-1"
        v-for="(p, index) in dataProducts"
        :key="index"
      >
        <div
          v-if="p.category == 'Vender' && p.available == 'Nao'"
          class="ribbon-wrapper ribbon-lg"
        >
          <div class="ribbon bg-danger text-white">Vendido</div>
        </div>

        <div
          v-if="p.category == 'Aluguer' && p.available == 'Nao'"
          class="ribbon-wrapper ribbon-lg"
        >
          <div class="ribbon bg-danger text-white">Arrendado</div>
        </div>
        <!-- :to="{ name: 'Details', params: { id: p.pid } }" -->

        <a
          href="javascript:void(0)"
          class="product-item bg-light mb-4"
          style="width: 100px"
        >
          <div
            @click="showDetails(p.pid)"
            style="border-top-left-radius: 12px; border-top-right-radius: 12px"
            class="product-img position-relative overflow-hidden"
          >
            <!-- :src="url_path + p.back_imag" -->
            <!-- src="http://localhost:8000/upload_images/room04.jpeg" -->
            <img
              v-if="p.back_imag != null"
              :src="getImageUrl(p.back_imag)"
              class="img-fluid w-100"
              style="height: 200px"
              alt="empty"
            />

            <!-- style="height: 200px; object-position: 50% 50%; object-fit: cover" -->
            <img
              v-else
              src="../../../public/assets/img/add_photo.webp"
              class="img-fluid w-100"
              style="height: 200px"
              alt="default img"
            />
          </div>
          <div>
            <!-- <a class="h6 text-decoration-none text-truncate" href="">{{p.title}}</a> -->
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="p.time_expired !== null"
            >
              <h6
                style="
                  text-decoration-line: line-through;
                  text-decoration-color: red;
                  font-size: 14px;
                "
              >
                {{ moneyFormat(p.price) }}
              </h6>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center"
            >
              <h6 style="font-size: 14px">{{ moneyFormat(p.price) }}</h6>
              <h6
                style="
                  text-decoration-line: line-through;
                  text-decoration-color: red;
                  font-size: 14px;
                "
                v-if="p.last_price != p.price"
              >
                {{ moneyFormat(p.last_price) }}
              </h6>
            </div>
          </div>
          <div class="d-flex justify-content-between"></div>
          <div
            class="d-flex align-items-center justify-content-between mb-1 py-2"
            style="
              background-color: #dae4ea;
              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
            "
          >
            <div class="ml-1">
              <small class="mr-1" style="font-weight: 700">{{ p.title }}</small>
            </div>

            <div>
              <small class="mr-1" v-if="p.time_expired !== null"
                ><i class="fa fa-wifi"></i>Disponível:&nbsp;<span
                  class="text-danger"
                  >Não
                </span></small
              >
              <small class="mr-1" v-if="p.wc === 'Sim'"
                ><i class="fa fa-bath"></i>&nbsp;Sim</small
              >
              <small class="mr-1" v-else
                ><i class="fa fa-bath"></i>&nbsp;Não</small
              >
              <small class="mr-1" v-if="p.qtybadRoom > 0"
                ><i class="fa fa-bed"></i>&nbsp;{{ p.qtybadRoom }}</small
              >
              <small class="mr-1" v-else
                ><i class="fa fa-bed"></i> &nbsp;{{ p.qtybadRoom }}</small
              >
            </div>
          </div>
        </a>
      </div>
    </div>

    <Page
      :total="total"
      :model-value="current_page"
      :page-size="parseInt(per_page)"
      @on-change="loadMoreProducts"
      v-if="total > 0"
      class="text-center mt-5 mb-5"
    />
  </div>

  <div id="block-screen" v-show="loading == true">
    <div class="process">
      <p class="text-center">
        Por favor aguarde enquanto finalizamos o seu processo...
      </p>
      <img class="img-fluid" src="/assets/img/processador_3.gif" alt="" />
    </div>
  </div>

  <!-- End modal video -->
</template>

<script setup>
import "jquery-slimscroll/jquery.slimscroll.min"; // Importe o SlimScroll
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
const modules = ref([Autoplay, Pagination, Navigation]);
// import GoogleMapsLoader from 'google-maps-api-loader';

import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { mapActions, useStore } from "vuex";
import ClientStoreModule from "./ClientStoreModule";

// import { Swiper, SwiperSlide } from 'swiper/vue';
// import 'swiper/css';
// import 'swiper/css/pagination';
//   import { Pagination } from 'swiper/modules';
//   import { Carousel, Slide } from 'vue-carousel';
import Rating from "../../components/Rating.vue";
import store from "@/store";
import BarMenu from "@/components/BarMenu.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import SearchProductBar from "@/components/SearchProductBar.vue";
import router from "@/router";
import axios from "axios";
import crypto from "../../Api/crypto";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import ProporsalStoreModule from "../Proporsal/ProporsalStoreModule";

const post_1 = require("@/assets/img/img-Pub-2.webp");
const post_2 = require("@/assets/img/imgPub-1.webp");
const ModalVisibleMap = ref(false);
const route = useRoute();
const map = ref(null);
const center = { lat: 40.689247, lng: -74.044502 };
// import { vInfiniteScroll } from '@vueuse/components'
// const localDefault = ref("http://localhost:8080/");
const ModalVisible = ref(false);
const modalvideoVisible = ref(false);

const dataProducts = ref([]);
const url_path = ref("http://localhost:8000/storage/assets/upload_images/");
// const url_path = ref("https://terrenos.co.mz/");
// const url_path = ref(
//   "https://isjamu.pt/ServerApi/storage/app/public/assets/upload_images/"
// );
// const url_path = "/Api/assets/upload_images/";

const modalContentRef = ref();
const noMoreItems = ref(false);
const page = ref(1);
const fullName = ref(null);
const newItem = ref([]);
const search = ref("");
const isOpen = ref(false);
const results = ref([]);
const items = ref([]);
const childhood = ref("");
const title = ref("");
const products = ref([]);
const optionActiveTabSearch = ref("Imobiliario");
const locationInfo = ref();
const loading = ref(false);
const total = ref();
const current_page = ref();
const per_page = ref();
const isModal = ref(false);
const contactAgent = ref(false);

const parametroBooleano = ref(true); // Substitua por seu valor booleano
const role = ref("");
const selectedImg = ref();

const dataProduct = ref({
  productCategory: "",
  title: null,
  price: "",
  category: "",
  private_gross: "",
  total_lot_area: "",
  qtybadRoom: "0",
  net_area: "",
  floor: "",
  lift: "",
  gross_area: "",
  build_year: "",
  wc: "Nao",
  parking: "",
  divisions: [],
  agent_id: "",
  name: "",
  contact: "",
  contact_alternative: "",
  email: "",
  whatsapp: "",
  district_id: "",
  province_id: "",
  description: "",
  childhood: "",
  imgSlide1: "",
  imgSlide2: "",
  imgSlide3: "",
  agent: {
    fullName: "",
    alternative_email: "asad3as1ssss@gmail.com",
    gender: "Masculino",
    whatsapp: "312312",
    doc_number: "123122",
    birthDate: "2023-09-07",
    marital_status: "Divorciado(a)",
    childhood_location: "dasdas",
    contact: "23122",
    contact_alternative: "12121",
  },

  Comments: [
    {
      product_id: "",
      type_review: "",
      comments: "",
    },
  ],
});

const proporsal = ref({
  pid: "",
  buy: "",
  whatyoulike: "",
  rating: "",
  aboutLocation: "",
  suggest: "",
  customer_contact: "",
  sendProporsal: "",
  email: "",
  alternative_contact: "",
});

const product_id = ref(null);
const clientMail = ref({
  fullName: "",
  contact: "",
  email: "",
  message: "",
  product_id: null,
  checkTerms: false,
});
const markerOptions = { position: center, label: "L", title: "LADY LIBERTY" };
const google = window.google;
const mapa = ref(null);
let mapp = ref(null);
const marker = ref(null);
const destination = ref("");
const places = ref([]);
const placeDestination = ref([]);
const placeService = ref("");
const markers = ref([]);
const markersDirections = ref([]);
let directionsService = ref();
let directionsRenderer = ref();
const userLocation = ref({
  lat: 0,
  lng: 0,
});
// Funcao para exibicao do mapa

//  function carregarMapa() {
//             // Obter a localização do usuário
//             navigator.geolocation.getCurrentPosition(
//                 posicao => {
//                     const latitude = posicao.coords.latitude;
//                     const longitude = posicao.coords.longitude;

//                     // Inicializar o mapa com as coordenadas obtidas
//                     initMap(latitude, longitude);
//                 },
//                 erro => {
//                     console.error('Erro ao obter localização:', erro);
//                 }
//             );
//     }

/*function initMap(lat, log){

  center.lat = lat
  center.lng = log
  markerOptions.position = center
  
}*/

function getImageUrl(filename) {
  // Gera a URL completa da imagem
  return `https://isjamu.pt/ServerApi/storage/app/public/assets/upload_images/${filename}`;
}

function Outside() {
  places.value = [];
}

function openMapModal() {
  // check if product coords
  if (userLocation.value.lat == 0 || userLocation.value.lat == NaN) {
    toastr.info(
      "Desculpe-nos mas ainda não é possivel exibir a localização deste produto no mapa"
    );
    return;
  }

  ModalVisibleMap.value = true;
}

function closeModalMap() {
  ModalVisibleMap.value = false;
}

//Get user current location
function getUserLocation(prodLocation) {
  const isSupported = "navigator" in window && "geolocation" in navigator;

  // if (isSupported) {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     const userCurrentLocation = {
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     };

  //     userLocation.value = userCurrentLocation;

  //     // console.log(userCurrentLocation);
  //     //  initMap(userCurrentLocation);

  //   });
  // }

  // console.log("coords", prodLocation);
  initMap(prodLocation);
}

// Init map
function initMap(userLocation) {
  // const center = { lat: -25.89548666666667, lng: 32.53563 };
  // getUserLocation();

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    map: mapp.value,
    suppressMarkers: true, //You can hide default Marker A and B using suppressMarkers property as true.
  });
  var LatLng = new google.maps.LatLng(userLocation);

  const options = {
    disableDefaultUI: true,
    scaleControl: true,
    center: LatLng,
    zoom: 15, // Nível de zoom inicial
  };

  mapp.value = new google.maps.Map(mapa.value, options);
  placeService.value = new google.maps.places.PlacesService(mapp.value);
  directionsRenderer.setMap(mapp.value); // Existing map object displays directions

  let inputSearch = document.getElementById("inpSearch");

  addMarker(LatLng);

  inputSearch.addEventListener("input", function () {
    const query = inputSearch.value;

    if (query.length >= 3) {
      getPlaces(query);
    } else {
      // clearMarkers();
    }
  });

  // addMarker(userLocation)
}

// Get place when user search
function getPlaces(textToSerch) {
  try {
    let request = {
      query: textToSerch,
      fields: ["name", "geometry"],
    };

    // console.log(textToSerch);
    placeService.value.textSearch(request, callback2);
  } catch (error) {}
}

// Set place on input when user select place
function placeChanged(place) {
  try {
    // hideMarkers()

    // console.log(marker.value);
    document.getElementById("inpSearch").value = place.name;

    hideMarkers();
    let userCurrentLocation = {};
    navigator.geolocation.getCurrentPosition((position) => {
      directionsService.route(
        {
          origin: {
            // lat: position.coords.latitude,
            // lng: position.coords.longitude,
            lat: userLocation.value.lat,
            lng: userLocation.value.lng,
          },
          destination: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          travelMode: "DRIVING",
        },
        function (response, status) {
          // anonymous function to capture directions
          if (status !== "OK") {
            window.alert("Directions request failed due to " + status);
            return;
          } else {
            directionsRenderer.setDirections(response); // Add route to the map
            var directionsData = response.routes[0].legs[0]; // Get data about the mapped route

            // let directionsDisplay = new google.maps.DirectionsRenderer({map: mapp.value, suppressMarkers: true});

            const startPosition = {
              lat: response.routes[0].legs[0].start_location.lat(),
              lng: response.routes[0].legs[0].start_location.lng(),
            };

            const endPosition = {
              lat: response.routes[0].legs[0].end_location.lat(),
              lng: response.routes[0].legs[0].end_location.lng(),
            };
            addEndMarker(endPosition);
            addMarker(startPosition);

            if (!directionsData) {
              window.alert("Directions request failed");
              return;
            } else {
              document.getElementById("msg").innerHTML =
                " A distância de condução é " +
                directionsData.distance.text +
                " (" +
                directionsData.duration.text +
                ").";
            }
          }
        }
      );
    });
  } catch (error) {}
}

function getPlacesDestination(destination) {
  try {
    let request = {
      query: destination,
      fields: ["name", "geometry"],
    };

    // let service = new google.maps.places.PlacesService(map.value);
    placeService.value.textSearch(request, callback2);
  } catch (error) {}
}

function callback2(results, status) {
  try {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      // clearMarkers();
      places.value = results;
      // for (let index = 0; index < results.length; index++) {
      // const place = results[index];
      // console.log(place);
      // createMarker(results[index], index);
      // }
    }
  } catch (error) {
    console.log(error);
  }
}

// Sets the map on all markers in the array.
function setMapOnAll(map) {
  for (let i = 0; i < markers.value.length; i++) {
    markers.value[i]._rawValue.setMap(null);
  }

  // markers.value.length = [];

  // console.log("set map null", markers.value);
}

// Sets the map on all markers in the array.
function setMapOnAllDirections(map) {
  for (let i = 0; i < markersDirections.value.length; i++) {
    markersDirections.value[i].visible = false;
  }

  markersDirections.value = [];
}

// Removes the markers from the map, but keeps them in the array.
function hideMarkers() {
  setMapOnAll(null);
  setMapOnAllDirections(null);
}

function addMarker(position) {
  try {
    marker.value = new google.maps.Marker({
      position: position,
      title: "Meu local actual",
      icon: "https://terrenos.co.mz/home.png",
    });

    marker.value.setMap(mapp.value);

    markers.value.push(marker);
  } catch (error) {
    console.log(error);
  }
}

function addEndMarker(position) {
  try {
    let EndMarker = new google.maps.Marker({
      position: position,
      title: "Destino",
    });

    EndMarker.setMap(mapp.value);
    markersDirections.value.push(EndMarker);
  } catch (error) {
    console.log(error);
  }
}
document.addEventListener("click", Outside);

function closeModal() {
  history.pushState(null, null, "/");

  isModal.value = false;
  document.body.style.overflow = ""; // Habilitar rolagem da página principal
  // Exibir elementos com a classe "back-to-top"
  const elements = document.getElementsByClassName("back-to-top");
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "block"; // Ou defina para o valor desejado
  }
}

function closeModalSlideImage() {
  ModalVisible.value = false;
}

function closeModalVideo() {
  modalvideoVisible.value = false;
}

function hashId(id) {
  return crypto.generateHash(id);
}

const { t, locale } = useI18n();
const storeData = useStore();
const lang = ref(storeData.state.auth.lang);

locale.value = lang.value;
function changeLanguage(local) {
  storeData.dispatch("auth/localLang", local);
  locale.value = local; // Altera o idioma
  //   console.log(t.locale);
}

function updateLocalData() {
  locale.value = storeData.state.auth.lang; // Altera o idioma
}
function setImgToShow(img) {
  selectedImg.value = img;
}

function openModal() {
  ModalVisible.value = true;
}

watch(
  () => storeData.state.auth.lang,
  (newValue, oldValue) => {
    updateLocalData();
  }
);

async function searchChildhoods(query) {
  const resp = await ClientStoreModule.actions.searchChildHood(query);
  items.value = resp.data;
}

async function searchProduct(query) {
  const resp = await ClientStoreModule.actions.searchProduct(query);
  products.value = resp.data;
}

async function findProducts(query, selected = optionActiveTabSearch.value) {
  router.push({
    name: "findByDistrict",
    params: { id: query, selected: selected },
  });
}

async function showDetails(productId) {
  router.push({ name: "details", params: { id: productId } });
}

async function findProdutcById(productId) {
  let prod = null;

  prod = dataProducts.value.filter((item) => item.pid == parseInt(productId));

  if (prod.length > 0) {
    console.log("findProductId", prod);
  } else {
    const resp = await ClientStoreModule.actions.getProductById(productId);
    prod = resp.data.data;
  }
  // set latitude and longitude
  if (prod.length > 0) {
    userLocation.value.lat =
      prod[0].lat != "undefined" ? parseFloat(prod[0].lat) : 0;
    userLocation.value.lng =
      prod[0].lng != "undefined" ? parseFloat(prod[0].lng) : 0;
    getUserLocation(userLocation.value);
  }

  history.pushState(null, null, "/detalhes/" + productId);
  proporsal.value.pid = productId;
  add_statistic_product(productId);
  isModal.value = true;
  document.body.style.overflow = "hidden"; // Desabilitar rolagem da página principal
  $(modalContentRef.value).slimScroll({
    height: "100%", // Defina a altura desejada
    alwaysVisible: true, // Barra de rolagem sempre visível
    // background: '#F6F6F6', // Barra de rolagem sempre visível
  });

  $(contactAgent.value).slimScroll({
    height: "300px", // Defina a altura desejada
    alwaysVisible: true, // Barra de rolagem sempre visível
  });

  const elements = document.getElementsByClassName("back-to-top");
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }

  // const paramId = crypto.decryptId(productId);
  let resp = dataProducts.value.filter(
    (item) => item.pid == parseInt(productId)
  )[0];

  // const resp = await ClientStoreModule.actions.getProductById(productId);

  dataProduct.value = resp;
  selectedImg.value = resp.back_imag;

  return;
}

async function add_statistic_product(pid) {
  const data = {
    product_id: pid,
  };
  const resp = ClientStoreModule.actions.add_statistic_product(data);
}

async function getReviews() {
  const paramId = crypto.decryptId(route.params.id);

  const resp = await ClientStoreModule.actions.getReviews(paramId);
  dataComments.value = resp.data.data;
}

// adiciona a proposta do cliente
async function submitProporsal() {
  try {
    loading.value = true;

    const resp = await ProporsalStoreModule.actions.addProporsal(
      proporsal.value
    );
    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      clearFields();
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

// limpa os campos
function clearFields() {
  proporsal.value.pid = "";
  proporsal.value.buy = "";
  proporsal.value.whatyoulike = "";
  proporsal.value.rating = "";
  proporsal.value.aboutLocation = "";
  proporsal.value.suggest = "";
  proporsal.value.customer_contact = "";
  proporsal.value.sendProporsal = "";
  proporsal.value.alternative_contact = "";
  proporsal.value.email = "";
}

onMounted(() => {
  //  initMap();
  // getUserLocation();
  if (localStorage.getItem("url")) {
    localStorage.removeItem("url");
  }
  if (route.params.id) {
    findProdutcById(route.params.id);
    console.log("log", route.params.id);
  }
  // Registas as visitas feitas no site
  ClientStoreModule.actions.visit();

  if (store.state.auth.authenticated) {
    role.value = store.state.auth.role;

    if (store.state.auth.role == "Superadmin") {
      window.location.href = "/dashboard";
    }

    fullName.value = store.state.auth.user.fullName;
  }
});

onBeforeMount(() => {
  getProduct();
  // showSuccessToast()
});

async function getProduct() {
  try {
    loading.value = true;

    const resp = await ClientStoreModule.actions.getProducts(1);
    dataProducts.value = resp.data.data;
    total.value = resp.data.meta.total;
    current_page.value = resp.data.meta.current_page;
    per_page.value = resp.data.meta.per_page;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

async function loadMoreProducts(page = 1) {
  // page.value++;
  noMoreItems.value = true;
  loading.value = true;
  const response = await ClientStoreModule.actions.loadMorePorduct(page);
  newItem.value = response.data.data;

  if (newItem.value.length === 0) {
    noMoreItems.value = true;
    loading.value = false;
  } else {
    dataProducts.value = response.data.data;
    // page.value +=1;
    noMoreItems.value = false;
    loading.value = false;
  }
}

const corDaDiv = (parametro) => {
  return computed(() => {
    return {
      "red-background": parametro,
      "red-blue": !parametro,
    };
  });
};

const isNewProduct = (productDateCreated) => {
  return computed(() => {
    const currentDate = new Date();
    var day = String(currentDate.getDate()).padStart(2, "0");
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
    var year = currentDate.getFullYear();

    const DateCreation = new Date(productDateCreated);
    const dayC = DateCreation.getDate();
    const monthC = DateCreation.getMonth() + 1;
    const yearC = DateCreation.getFullYear();

    const CurrentdateFormatted = new Date(`${year}/${month}/${day}`);
    const DateCreationFormatted = new Date(`${yearC}/${monthC}/${dayC}`);
    var difdateCurrentMill = Math.abs(
      CurrentdateFormatted - DateCreationFormatted
    );
    var diferenceDaysCurrent = Math.ceil(
      difdateCurrentMill / (1000 * 60 * 60 * 24)
    );

    // console.log(diferenceDaysCurrent);

    if (diferenceDaysCurrent === 5) {
      return {
        "red-background": diferenceDaysCurrent === 5,
      };
    } else {
      return {
        "red-blue": diferenceDaysCurrent > 5,
      };
    }
  });
};

// function isNewProduct(productDateCreated){

//     const currentDate = new Date();
//     var day = String(currentDate.getDate()).padStart(2, "0");
//     var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Os meses em JavaScript são indexados a partir de 0
//     var year = currentDate.getFullYear();

//     const DateCreation = new Date(productDateCreated);
//     const dayC = DateCreation.getDate();
//       const monthC = DateCreation.getMonth() + 1;
//       const yearC = DateCreation.getFullYear();

//     const CurrentdateFormatted = new Date(`${year}/${month}/${day}`);
//     const DateCreationFormatted = new Date(`${yearC}/${monthC}/${dayC}`);
//     var difdateCurrentMill = Math.abs(CurrentdateFormatted - DateCreationFormatted);
//     var diferenceDaysCurrent = Math.ceil(
//         difdateCurrentMill / (1000 * 60 * 60 * 24)
//         );
//    return diferenceDaysCurrent;

// }

function formatDates(dateFormat) {
  const data = new Date(dateFormat);

  const day = data.getDate();
  const month = data.getMonth() + 1;
  const year = data.getFullYear();
  const hours = data.getHours();
  const minutes = data.getMinutes();
  const seconds = data.getSeconds();

  return `${day}/${month}/${year} `;
}
</script>

<style scoped>
.red-background {
  background-color: #dae4ea;
}

.red-blue {
  background-color: blue;
}

.search {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 20%;
  right: 20%;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.swiper-slide div {
  width: 800px;
  height: 800px;
}

.swiper-slide div img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.swiper-slide div video {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

#maps {
  position: relative;
  height: 400px;
  width: 100%;
  margin: 0;
  padding: 0;
}

#floating-panel-destination {
  position: absolute;
  background-color: #4d90fe;
  top: 70px;
  left: 5%;
  z-index: 999;
  background-color: #fff;
  text-align: center;
  font-family: "Roboto", "sans-serif";
  line-height: 30px;
  padding-left: 10px;
}

#floating-panel {
  position: absolute;
  background-color: #4d90fe;
  top: 10px;
  left: 15%;
  z-index: 9999;
  background-color: #fff;
  text-align: center;
  font-family: "Roboto", "sans-serif";
  line-height: 30px;
  padding-left: 10px;
}

#floating-search {
  position: absolute;
  background-color: #fff;
  top: 55px;
  left: 0;
  z-index: 99999;
  font-family: "Roboto", "sans-serif";
  width: 100%;
  min-height: 120px;
  max-height: 320px;
  overflow-y: auto;
}

#floating-search-destination {
  position: absolute;
  background-color: #fff;
  top: 40px;
  left: 0;
  z-index: 99999;
  border: 1px solid #999;
  font-family: "Roboto", "sans-serif";
  width: 100%;
  height: 120px;
  overflow-y: scroll;
}

ul {
  list-style: none;
  text-align: left;
  margin: 0;
}

ul li {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  padding-top: 5px;
  padding-left: 5px;
  cursor: pointer;
  color: #444;
  border-bottom: 1px solid #ddd;
}

ul li:hover {
  background: tomato;
  color: #fff;
}
ul li p {
  color: #999;
  font-size: 11px;
  margin-top: 5px;
}

#description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  font-family: Roboto;
  padding: 0;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

#target {
  width: 345px;
}

.card_image {
  width: 180px;
  height: 180px;
  border-top-left-radius: 10px;
}

.card_image img {
  width: 180px;
  object-fit: cover;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>

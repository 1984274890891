<template>
  <!-- Navbar Start -->
  <BarMenu
    :fullName="name"
    :userLogged="userLoggod"
    :lang="lang"
    :role="role"
  />

  <!-- Navbar End -->

  <!-- Main content -->
  <div class="container pt-5 pb-3 bg-white mb-5">
    <div class="row">
      <div class="col-12 col-sm-8">
        <div class="col-lg-12">
          <swiper
            :style="{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }"
            :loop="true"
            :spaceBetween="10"
            :navigation="true"
            :thumbs="{ swiper: thumbsSwiper }"
            :modules="modules"
            class="mySwiper2"
          >
            <swiper-slide v-for="(p, index) in dataProduct.images" :key="index"
              ><img :src="getImageUrl(p.imgSlide1)"
            /></swiper-slide>
          </swiper>
          <swiper
            @swiper="setThumbsSwiper"
            :loop="true"
            :spaceBetween="10"
            :slidesPerView="4"
            :freeMode="true"
            :watchSlidesProgress="true"
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide v-for="(p, index) in dataProduct.images" :key="index"
              ><img :src="getImageUrl(p.imgSlide1)"
            /></swiper-slide>
          </swiper>
        </div>

        <div class="col-lg-12 mt-4">
          <div id="overview">
            <h4>
              {{ t("PageDateils.Description") }}
            </h4>
            {{ dataProduct.description }}
          </div>
          <div class="mt">
            <div class="d-flex align-content-center text-center mb-3 mt-5">
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                style="
                  background: #e6f4fb;
                  border-radius: 6px;
                  font-weight: 700;
                "
              >
                <i class="fa fa-database text-success"></i>
                {{ moneyFormat(dataProduct.price) }}
              </button>
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-if="
                  dataProduct.category == 'Vender' &&
                  dataProduct.available == 'Sim'
                "
                style="
                  background: #e6f4fb;
                  border-radius: 6px;
                  font-weight: 700;
                "
              >
                <i class="fa fa-wifi text-success"></i>
                {{ t("PageDateils.ProductAvailableSell") }}
              </button>

              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-if="
                  dataProduct.category == 'Aluguer' &&
                  dataProduct.available == 'Sim'
                "
                style="background: #e6f4fb; border-radius: 6px"
              >
                <i class="fa fa-check"></i>
                {{ t("PageDateils.ProductAvailableRent") }}
              </button>
              <!-- <span style="width:7em" class="btn btn-outline-info" for="size-1" v-if="dataProduct.category == 'Vender'">A venda</span>
                                    <label style="width:7em" class="badge badge-success" for="size-1" v-if="dataProduct.category == 'Aluguer'">Aluguer</label> -->
              &nbsp;
              <!-- <strong>WCs:</strong> &nbsp;
                                    <label class="custom-control-labl" for="size-2">{{dataProduct.wc}}</label> -->
              &nbsp;

              <!-- <strong>Quartos:</strong>&nbsp;
                                    <label class="custom-control-abel" for="size-3">{{dataProduct.qtybadRoom}}</label> -->
            </div>
            <div class="d-flex align-content-center text-center">
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-if="
                  dataProduct.category == 'Vender' &&
                  dataProduct.available == 'Sim'
                "
                style="background: #e6f4fb; border-radius: 6px"
              >
                Wc: {{ dataProduct.wc }}
                <i class="fa fa-check text-success"></i>
              </button>
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-if="dataProduct.qtybadRoom > 0"
                style="background: #e6f4fb; border-radius: 6px"
              >
                {{ t("PageDateils.Bedroom") }}: {{ dataProduct.qtybadRoom }}
                <i class="fa fa-check text-success"></i>
              </button>
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-else
                style="background: #e6f4fb; border-radius: 6px"
              >
                {{ t("PageDateils.Bedroom") }}:
                <i class="fa fa-times text-danger"></i>
              </button>
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-if="dataProduct.lift == 'Sim'"
                style="background: #e6f4fb; border-radius: 6px"
              >
                {{ t("PageDateils.Lift") }}: {{ dataProduct.lift }}
                <i class="fa fa-check text-success"></i>
              </button>
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-else
                style="background: #e6f4fb; border-radius: 6px"
              >
                {{ t("PageDateils.Lift") }}:
                <i class="fa fa-times text-danger"></i>
              </button>
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-if="dataProduct.parking == 'Sim'"
                style="background: #e6f4fb; border-radius: 6px"
              >
                {{ t("PageDateils.Parking") }}: {{ dataProduct.parking }}
                <i class="fa fa-check text-success"></i>
              </button>
              <button
                type="button"
                class="btn btn-light text-primary mr-2"
                v-else
                style="background: #e6f4fb; border-radius: 6px"
              >
                {{ t("PageDateils.Parking") }}:
                <i class="fa fa-times text-danger"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-4" style="padding-left: 10px">
        <div class="row" style="padding-left: 10px">
          <div class="card border">
            <div class="card-body">
              <form @submit.prevent="submitAgentForm">
                <!-- personal User data -->

                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Check in*</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="bookingForm.checkIn"
                        placeholder="check in"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Check out*</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="bookingForm.checkout"
                        placeholder="check out"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Guests*</label>
                      <input
                        type="text"
                        class="form-control"
                        @keypress="numberOnly"
                        maxlength="9"
                        v-model="bookingForm.guests"
                        placeholder="Guests"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Rooms*</label>

                      <input
                        type="text"
                        class="form-control"
                        v-model="bookingForm.rooms"
                        placeholder="Rooms"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="container-fluid">
                    <div class="row">
                      <!-- accepted payments column -->

                      <!-- /.col -->
                      <div class="col-12">
                        <p class="lead">Amount Due</p>

                        <div class="table-responsive">
                          <table class="table">
                            <tr>
                              <th>Total:</th>
                              <td v-if="dataProduct.price != ''">
                                ${{ dataProduct.price }}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->
                  </div>

                  <!-- <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Services*</label>

                      <select
                        type="text"
                        class="form-control"
                        v-model="bookingForm.services"
                        placeholder="Rooms"
                      ></select>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">-----</label>

                      <select
                        type="text"
                        class="form-control"
                        placeholder="Rooms"
                      ></select>
                    </div>
                  </div> -->

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <button
                      type="submit"
                      class="btn btn-success"
                      v-if="loading == false"
                    >
                      Book Now
                    </button>
                    <button
                      v-else
                      type="button"
                      disabled="true"
                      class="btn btn-success"
                    >
                      Booking...
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- /.content -->

  <div id="block-screen" v-show="loading == true">
    <div class="process">
      <p class="text-center">
        Por favor aguarde enquanto finalizamos o seu processo...
      </p>
      <img class="img-fluid" src="/assets/img/processador_3.gif" alt="" />
    </div>
  </div>

  <!-- End modal video -->
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ProductStoreModule from "../Products/ProductStoreModule";
import ClientStoreModule from "./ClientStoreModule";
import Rating from "../../components/Rating.vue";
import SearchProductBar from "@/components/SearchProductBar.vue";
import store from "@/store";
import BarMenu from "@/components/BarMenu.vue";
import Swal from "sweetalert2";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/thumbs";
import "swiper/css/free-mode";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {
  FreeMode,
  Thumbs,
  Autoplay,
  Pagination,
  Navigation,
} from "swiper/modules";
import crypto from "../../Api/crypto";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const modules = ref([FreeMode, Thumbs, Autoplay, Pagination, Navigation]);

// const url_path = ref("http://localhost:8000/storage/assets/upload_images/");
const url_path =
  "https://isjamu.pt/ServerApi/storage/app/public/assets/upload_images/";

// const url_path2 = ref("http://localhost:8000/assets/upload_images/");
const products = ref([]);

const ModalVisible = ref(false);
const dataProduct = ref({
  productCategory: "",
  title: null,
  price: "",
  category: "",
  private_gross: "",
  total_lot_area: "",
  qtybadRoom: "0",
  net_area: "",
  floor: "",
  lift: "",
  gross_area: "",
  build_year: "",
  wc: "Nao",
  parking: "",
  divisions: [],
  agent_id: "",
  name: "",
  contact: "",
  contact_alternative: "",
  email: "",
  whatsapp: "",
  district_id: "",
  province_id: "",
  description: "",
  childhood: "",
  imgSlide1: "",
  imgSlide2: "",
  imgSlide3: "",
  agent: {
    fullName: "",
    alternative_email: "asad3as1ssss@gmail.com",
    gender: "Masculino",
    whatsapp: "312312",
    doc_number: "123122",
    birthDate: "2023-09-07",
    marital_status: "Divorciado(a)",
    childhood_location: "dasdas",
    contact: "23122",
    contact_alternative: "12121",
  },

  Comments: [
    {
      product_id: "",
      type_review: "",
      comments: "",
    },
  ],
});

const thumbsSwiper = ref(null);

const setThumbsSwiper = (swiper) => {
  console.log(swiper);

  thumbsSwiper.value = swiper;
};

const Comment = ref({
  product_id: "",
  type_review: "",
  comment: "",
});
const userComent = ref("");
const loading = ref(false);
const role = ref("User");
const name = ref(null);
const route = useRoute();
const bookingForm = ref({
  checkIn: "",
  checkout: "",
  guests: "",
  rooms: "",
  services: route.params.id,
  itemId: route.params.id,
});

const selectedImg = ref();
const dataComments = ref([]);
const urlFacebook = `https://terrenos.co.mz/details/${crypto.generateHash(
  route.params.id
)}`;
const isSdkLoaded = ref(false);
const userLoggod = ref(false);
const modalvideoVisible = ref(false);
const { t, locale } = useI18n();
const storeData = useStore();
const lang = ref(storeData.state.auth.lang);

locale.value = lang.value;

function updateLocalData() {
  locale.value = storeData.state.auth.lang; // Altera o idioma
}

watch(
  () => storeData.state.auth.lang,
  (newValue, oldValue) => {
    console.log(newValue);
    updateLocalData();
  }
);

function getImageUrl(filename) {
  // Gera a URL completa da imagem
  return `https://isjamu.pt/ServerApi/storage/app/public/assets/upload_images/${filename}`;
}

function compartilharLink() {
  // Use a função FB.ui para abrir a janela de compartilhamento do Facebook
  window.FB.ui(
    {
      method: "share",
      href: "https://terrenos.co.mz/details/13",
    },
    function (response) {
      if (response && !response.error_message) {
        console.log("Link compartilhado com sucesso.");
      } else {
        console.error("Erro ao compartilhar o link: " + response.error_message);
      }
    }
  );
}
function closeModal() {
  ModalVisible.value = false;
}

function closeModalVideo() {
  modalvideoVisible.value = false;
}
function openModal() {
  ModalVisible.value = true;
}

function openModalVideo() {
  modalvideoVisible.value = true;
}

function clearInputs() {
  bookingForm.value.checkIn = "";
  bookingForm.value.checkout = "";
  bookingForm.value.guests = "";
}
async function submitAgentForm() {
  loading.value = true;
  // console.log(window.location.pathname);

  if (!store.state.auth.authenticated) {
    loading.value = false;

    localStorage.setItem("url", window.location.pathname);

    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Por favor acesse a sua conta",
      showConfirmButton: false,
      timer: 1500,
    });
    // setTimeout(() => {
    //   window.location.href = "/login";
    // }, 5000);

    return;
  }
  if (
    bookingForm.value.checkIn == "" ||
    bookingForm.value.checkout == "" ||
    bookingForm.value.guests == "" ||
    bookingForm.value.rooms == ""
  ) {
    loading.value = false;
    return Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Por favor preencha todos os campos",
      showConfirmButton: false,
      timer: 1500,
    });
  }

  const resp = await ClientStoreModule.actions.sentEmailToProductOwner(
    bookingForm.value
  );

  if (resp.data.status == true) {
    clearInputs();

    Swal.fire({
      position: "top-end",
      icon: "success",
      title: resp.data.message,
      showConfirmButton: false,
      timer: 1500,
    });

    localStorage.setItem("amount", resp.data.price);
    localStorage.setItem("bookingId", resp.data.id);
    setTimeout(() => {
      window.location.href = `/payment/checkout/${resp.data.prodId}`;
    }, 2000);
  }
  loading.value = false;

  // requisicao de pagamento do paypal
  // const url_redirect = await ClientStoreModule.actions.payBooking({
  //   amount: 100,
  // });

  // window.location.href = `${url_redirect.data.redirect_url}`;
}

async function copyUrl() {
  const paramId = crypto.generateHash(route.params.id);
  await navigator.clipboard.writeText(
    "https://terrenos.co.mz/details/" + paramId
  );
  toastr.success("Copiado");
}

async function searchProduct(query) {
  const resp = await ClientStoreModule.actions.searchProduct(query);
  products.value = resp.data;
  console.log(resp.data);
}

async function findProdutcById() {
  // const paramId = crypto.decryptId(route.params.id);

  try {
    loading.value = true;

    const resp = await ClientStoreModule.actions.getProductById(
      route.params.id
    );

    dataProduct.value = resp.data.data[0];
    selectedImg.value = resp.data.data[0].back_imag;
    bookingForm.value.rooms = resp.data.data[0].qtybadRoom;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }

  // if (resp.data.data.length > 0 && resp.data.data != undefined) {
  //   // console.log(resp.data.data);
  // }
}

async function add_statistic_product() {
  const data = {
    product_id: crypto.decryptId(route.params.id),
  };
  const resp = ClientStoreModule.actions.add_statistic_product(data);
}

function setImgToShow(img) {
  selectedImg.value = img;
}

async function getReviews() {
  const paramId = crypto.decryptId(route.params.id);

  const resp = await ClientStoreModule.actions.getReviews(paramId);
  dataComments.value = resp.data.data;
  console.log(resp.data);
}

async function very_angry(value) {
  store.state.auth.user.user_id;

  Comment.value.product_id = crypto.decryptId(route.params.id);
  Comment.value.type_review = value;
  Comment.value.comment = userComent.value;
  const resp = await ClientStoreModule.actions.addReview(Comment.value);
}
async function angry(value) {
  Comment.value.product_id = crypto.decryptId(route.params.id);
  Comment.value.type_review = value;
  Comment.value.comment = userComent.value;
  const resp = await ClientStoreModule.actions.addReview(Comment.value);

  console.log(resp);
}

async function neutro(value) {
  Comment.value.product_id = crypto.decryptId(route.params.id);
  Comment.value.type_review = value;
  Comment.value.comment = userComent.value;
  const resp = await ClientStoreModule.actions.addReview(Comment.value);
}

async function happy(value) {
  Comment.value.product_id = crypto.decryptId(route.params.id);
  Comment.value.type_review = value;
  Comment.value.comment = userComent.value;
  const resp = await ClientStoreModule.actions.addReview(Comment.value);
}
async function very_happy(value) {
  Comment.value.product_id = crypto.decryptId(route.params.id);
  Comment.value.type_review = value;
  Comment.value.comment = userComent.value;
  const resp = await ClientStoreModule.actions.addReview(Comment.value);
}

onMounted(async () => {
  findProdutcById();

  if (store.state.auth.authenticated == true) {
    role.value = store.state.auth.user.role;
    name.value = store.state.auth.user.fullName;
    userLoggod.value = store.state.auth.authenticated;
  }
});

function formatDates(dateFormat) {
  const data = new Date(dateFormat);

  const day = data.getDate();
  const month = data.getMonth() + 1;
  const year = data.getFullYear();
  const hours = data.getHours();
  const minutes = data.getMinutes();
  const seconds = data.getSeconds();

  return `${day}/${month}/${year} `;
}

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}
</script>

<style scoped>
body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
